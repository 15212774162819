<template>
  <v-card id="consent-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-col
      cols="12"
      style="padding-bottom:0px; margin-bottom:0px"
    >
      <v-alert
        v-show="alert.show"
        :color="alert.color"
        text
        class="mb-0"
      >
        <div class="d-flex align-start">
          <v-icon color="warning">
            {{ icons.mdiAlertOutline }}
          </v-icon>

          <div class="ms-3">
            <p class="text-base font-weight-medium mb-1 justify-right">
              {{ alert.message }}
            </p>
            <a
              align="right"
              href="javascript:void(0)"
              title="Hide this message"
              :class="alert.class"
              @click.prevent="hideAlert"
            >
              <span class="text-sm">{{ alert.callToAction }}</span>
            </a>
          </div>
        </div>
      </v-alert>
    </v-col>

    <!-- loading icon -->
    <div
      v-show="isLoading"
      id="misc"
    >
      <div class="page-title text-center justify-center px-5">
        <div class="misc-character d-flex justify-center">
          <v-img
            max-width="100"
            margin="100"
            src="@/assets/images/misc/loading-spinner-dark.gif"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- tabs item -->
    <v-tabs-items
      v-show="!isLoading"
      v-model="tab"
    >
      <v-tab-item>
        <consent-settings-general
          :consent-data="consentData"
          :consent-data-original="consentDataOriginal"
          :mode="mode"
          :controls="controls"
        ></consent-settings-general>
      </v-tab-item>
    </v-tabs-items>

    <v-card
      flat
      class="pa-0 mt-2"
    >
      <v-card-text>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                :disabled="!isFormValid && consentData.status !== 'LOCKED'"
                @click.prevent="saveConsent"
              >
                Save & Exit
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click.prevent="cancel"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              v-if="mode === 'update'"
              cols="6"
              class="text-right"
            >
              <v-btn
                color="error"
                outlined
                class="me-3 mt-4"
                type="reset"
                @click="deleteItem"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiRobotOutline,
  mdiFingerprint, mdiWavesArrowRight, mdiAutoFix, mdiFormSelect, mdiBadgeAccountOutline,
  mdiBadgeAccountAlertOutline, mdiProtocol, mdiLockOutline, mdiKeyChain, mdiFormatListChecks,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import { getConsent, listControls } from '../../graphql/queries'
import { createConsent, updateConsent, deleteConsent } from '../../graphql/mutations'
import store from '../../store'

// demos
import ConsentSettingsGeneral from './ConsentSettingsGeneral.vue'

export default {
  /**
   * components
   */
  components: {
    ConsentSettingsGeneral,
  },

  /**
   * data
   */
  data() {
    return {
      mode: this.$route.params.id === 'new' ? 'create' : 'update',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      dialogDelete: false,
      consentData: {},
      consentDataOriginal: {},
      controls: [],
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * isFormValid
     */
    isFormValid() {
      const missingFields = []
      if (this.mode === 'update' && !this.consentData.id) {
        missingFields.push('ID')
      }
      if (!this.consentData.status) {
        missingFields.push('Status')
      }
      if (!this.consentData.control) {
        missingFields.push('Control')
      }
      if (!this.consentData.recipient) {
        missingFields.push('Recipient')
      }
      QuasrHelper.log('Missing fields: '.concat(JSON.stringify(missingFields)))

      return missingFields.length === 0
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    await this.getConsent()
    this.getControls()
    this.isLoading = false
  },

  /**
   * methods
   */
  methods: {
    /**
     * getConsent
     */
    async getConsent() {
      this.isLoading = true
      if (this.mode === 'create') {
        this.consentData = {
          type: 'consent',
          status: 'ENABLED',
          config: {},
        }
      } else {
        try {
          const consent = await store.getAppSyncClient().query({ query: gql(getConsent), variables: { id: this.$route.params.id } })
          if (!consent.data.getConsent.config) consent.data.getConsent.config = {}
          this.consentData = consent.data.getConsent
        } catch (err) {
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.cancel()
          }
        }
      }
      this.consentDataOriginal = JSON.parse(JSON.stringify(this.consentData))
      this.isLoading = false
    },

    /**
     * getControls
     */
    async getControls() {
      const controls = await store.getAppSyncClient().query({ query: gql(listControls), variables: { limit: 1000 } })
      this.controls = controls.data.listControls.items
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
    },

    /**
     * deleteItem
     */
    deleteItem() {
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteConsent(this.consent.id)
      this.closeDelete()
    },

    /**
     * deleteConsent
     */
    async deleteConsent(id) {
      this.isLoading = true
      await store.getAppSyncClient().mutate({ mutation: gql(deleteConsent), variables: { input: { id } } })
      this.showAlert('Consent successfully deleted.', 'OK')
      this.cancel()
    },

    /**
     * saveConsent
     */
    async saveConsent() {
      this.isLoading = true
      const obj = {
        label: this.consentData.label,
        status: this.consentData.status,
      }
      if (this.mode === 'create') {
        obj.control = this.consentData.control
        obj.recipient = this.consentData.recipient
        const cmd = await store.getAppSyncClient().mutate({ mutation: gql(createConsent), variables: { input: obj } })
        if (cmd.data.createConsent) {
          this.consentData = cmd.data.createConsent
          this.mode = 'update'
          this.showAlert('Consent saved successfully.', 'OK', 'success')
          this.cancel()
        } else {
          this.showAlert('Saving consent failed.', 'OK', 'error')
          this.isLoading = false
        }
      } else if (this.mode === 'update') {
        obj.id = this.consentData.id
        const cmd = await store.getAppSyncClient().mutate({ mutation: gql(updateConsent), variables: { input: obj } })
        if (cmd.data.updateConsent) {
          this.showAlert('Consent saved successfully.', 'OK', 'success')
          this.cancel()
        } else {
          this.showAlert('Saving consent failed.', 'OK', 'error')
          this.isLoading = false
        }
      }
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const tab = ref('')
    const isLoading = true

    // tabs
    const tabs = [
      { title: 'Consent Settings', icon: mdiFormatListChecks },
    ]

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      isLoading,
      loginStatus,
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiRobotOutline,
        mdiFingerprint,
        mdiBadgeAccountAlertOutline,
        mdiBadgeAccountOutline,
        mdiWavesArrowRight,
        mdiAutoFix,
        mdiFormSelect,
        mdiProtocol,
        mdiLockOutline,
        mdiKeyChain,
        mdiFormatListChecks,
      },
    }
  },
}
</script>
